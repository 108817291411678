import { Link } from 'gatsby'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// form
// import ResourcesForm from '../components/forms/resources-form'
import Layout from '../components/Layout'
import LogoRow from '../components/LogoRow'
import SendAMessage from '../components/forms/send-a-message'
import SEO from '../components/seo'
//swiper
import TestimonialSwiper from '../components/swiper/testimonial-swiper'
import aboutcredentialbcc from '../images/aboutsandy-credential-bcc-min.png'
import aboutcredentialfache from '../images/aboutsandy-credential-fache-min.png'
import aboutcredentialkolbe from '../images/aboutsandy-credential-kolbe.png'
import aboutcredentialpccicf from '../images/aboutsandy-credential-pccicf-min.png'
import aboutcredentialpci from '../images/aboutsandy-credential-pci-min.png'
//images
import CoachingImg from '../images/coaching-img-new-v1.png'
// import MaleCoachImg from '../images/new-homepage/male-coach-thumbnail.png'
// import FemaleCoachImg from '../images/new-homepage/female-coach-thumbnail.png'
import HeadshotThumbnail1 from '../images/new-homepage/head-thumbnail1.png'
import HeadshotThumbnail2 from '../images/new-homepage/head-thumbnail2.png'
import HeadshotThumbnail3 from '../images/new-homepage/head-thumbnail3.png'
import HeadshotThumbnail4 from '../images/new-homepage/head-thumbnail4.png'
import HeadshotThumbnail5 from '../images/new-homepage/head-thumbnail5.png'
// icons
import MusicIcon from '../images/new-homepage/music.svg'
import PhysicianPodcast from '../images/new-homepage/physician-podcast.jpg'
import PhysicianResources from '../images/new-homepage/physician-resource.jpg'
import SandyScottImg from '../images/new-homepage/sandy-scott.png'
import SpotifyIcon from '../images/new-homepage/spotify.svg'
// import Step1Img from '../images/new-homepage/step-1.png'
// import Step2Img from '../images/new-homepage/step-2.png'
// import Step3Img from '../images/new-homepage/step-3.png'
import ama from '../images/ama.png'
import StepRound from '../images/new-homepage/steps-circle.png'
import UsImg from '../images/new-homepage/us-edited.png'
// sass styling
import '../sass/main.scss'

import HeroSwiper from '../components/swiper/hero-swiper.js'

// import SubscriptionForm from '../components/forms/subscription.jsxxx'

const IndexPage = ({ location, data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="homepage-wrapper">
          <section
            className="hero-wrapper"
            style={{ boxShadow: 'inset 0 0 0 1000px rgba(255,255,255,0.15)' }}
          >
            <article className="relative" style={{ minHeight: 'inherit' }}>
              <div className="container" style={{ minHeight: 'inherit' }}>
                <div
                  className="row align-lg-items-center align-items-md-center align-items-start pt-lg-0 pt-md-0 pt-4"
                  style={{ minHeight: '100vh' }}
                >
                  <div
                    className="col-lg-7 col-md-9 col-8 ml-lg-auto ml-md-auto ml-auto pt-4 hero-content"
                    style={{ opacity: '1' }}
                  >
                    <h1>
                      Invest in yourself
                      <br />
                      so you can
                      <div
                        className="d-lg-none d-md-block d-block block-separator"
                        style={{ height: '1rem' }}
                      >
                        <br />
                      </div>
                      <div className="ml-lg-0 mt-4 ml-md-0 ml-0 d-lg-inline-block d-md-inline-block d-inline-block text-breaker-wrapper">
                        <HeroSwiper />
                      </div>
                      <div
                        className="d-lg-block d-md-block d-block"
                        style={{ height: '1rem' }}
                      >
                        <br />
                      </div>
                      in 2025
                    </h1>
                    <div className="mt-4">
                      <Link to="/request" className="btn-default btn-purple">
                        Request a Free Coaching Session
                      </Link>
                    </div>
                    <div className="sponsor-logo">
                      <img src={ama} alt="AMA" className="img-fluid my-4" />
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="request-coaching-wrapper">
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-lg-auto text-center">
                    <h2>
                      Every physician has a secret dream.
                      <br />
                      We help you achieve yours.
                    </h2>
                  </div>
                </div>
              </div>
            </article>
            <article className="request-coaching-boxes">
              <div className="container">
                <div className="row py-5">
                  <div
                    className="mx-3 col-lg-2 ml-lg-auto col-md-5 ml-md-auto col-5 ml-auto request-coaching-box box-bg-dark mb-lg-0 mb-md-0 mb-4"
                    style={{
                      transform: 'rotate(-5deg)',
                    }}
                  >
                    <p className="text-white py-4 text-center mb-0">
                      lead with <br />
                      authenticity
                    </p>
                  </div>
                  <div
                    className="mx-2 col-lg-2 mt-lg-0 col-md-5 mr-md-auto col-5 mr-auto mt-md-0 mt-0 mb-lg-0 mb-md-0 mb-4 request-coaching-box box-bg-light"
                    style={{
                      transform: 'rotate(2deg)',
                    }}
                  >
                    <p className="text-white py-4 text-center mb-0">
                      work-life
                      <br />
                      mastery
                    </p>
                  </div>
                  <div
                    className="mx-3 col-lg-2 mt-lg-0 col-md-5 ml-md-auto col-5 ml-auto mt-md-5 mt-4 mb-lg-0 mb-md-0 mb-4 request-coaching-box box-bg-dark"
                    style={{
                      transform: 'rotate(6deg)',
                    }}
                  >
                    <p className="text-white py-4 text-center mb-0">
                      unlock full
                      <br />
                      potential
                    </p>
                  </div>
                  <div
                    className="mx-3 col-lg-2 mt-lg-0 col-md-5 col-5 mr-auto mr-md-auto mt-md-5 mt-4 mb-lg-0 mb-md-0 mb-4 request-coaching-box box-bg-light"
                    style={{
                      transform: 'rotate(-3deg)',
                    }}
                  >
                    <p className="text-white py-4 text-center mb-0">
                      inspire
                      <br />
                      high-performers
                    </p>
                  </div>
                  <div
                    className="mx-3 col-lg-2 mr-lg-auto col-md-5 mx-md-auto col-5 mx-auto mt-lg-0 mt-md-5 mt-4 mb-lg-0 mb-md-0 mb-4 request-coaching-box box-bg-dark"
                    style={{
                      transform: 'rotate(6deg)',
                    }}
                  >
                    <p className="text-white py-4 text-center mb-0">
                      navigate politics
                      <br />
                      with ease
                    </p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12 text-center">
                    <Link to="/request" className="btn-default btn-purple">
                      Request a Free Coaching Session
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="coaching-process-wrapper">
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center mb-lg-5 mb-md-5">
                    <h2>
                      Coaching is a process physicians
                      <br />
                      use to transform their life.
                    </h2>
                  </div>
                  <div className="col-lg-6 col-md-6 col-11 ml-lg-auto mx-md-auto mx-auto  mt-lg-0 mt-md-0 mt-5 h-100">
                    <div className="coaching-img h-100 p-4 text-center">
                      <div className="h-100 w-100">
                        <img
                          src={CoachingImg}
                          alt="Coaching"
                          className="img-fluid my-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-11 mr-lg-auto mx-md-auto mx-auto">
                    <div className="boxes d-flex p-lg-4 p-md-3 p-4 mt-lg-0 mt-md-0 mt-4 align-items-center">
                      <span className="mr-lg-4 mr-md-3 mr-4">3x</span>
                      <p className="mb-0">quality of life improvement</p>
                    </div>
                    <div className="boxes d-flex mt-lg-4 mt-md-4 mt-4 p-4 p-lg-4 p-md-3 align-items-center">
                      <span className="mr-lg-4 mr-md-3 mr-4">100%</span>
                      <p className="mb-0">
                        of our clients recommend
                        <br />
                        coaching to a colleague
                      </p>
                    </div>
                    <div className="boxes d-flex mt-lg-4 mt-md-4 mt-4 p-lg-4 p-md-3 p-4 align-items-center">
                      <span className="mr-lg-4 mr-md-3 mr-4">2x</span>
                      <p className="mb-0">more meaning at work</p>
                    </div>
                    <div className="text-lg-left text-md-left text-center">
                      <a
                        href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=%22physician%22+%22coaching%22&btnG="
                        rel="noopener noreferrer"
                        target="_blank"
                        className="btn-default btn-purple text-capitalize"
                        style={{ marginTop: '5rem' }}
                      >
                        What The Research Says
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="coaching-steps-wrapper">
            <article className="pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center mb-lg-5 mb-md-5 mb-5">
                    <h2>
                      We're here to guide you every
                      <br />
                      step of the way.
                    </h2>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="step-boxes p-4 h-100">
                      <div className="step-boxes-numbers">
                        <img src={StepRound} alt="Step 1" />
                        <p className="mb-0">1.</p>
                      </div>
                      <span className="d-block mt-4">Stage one</span>
                      <h3 className="my-3">Get Your House in Order</h3>
                      <p>
                        Everybody needs a solid foundation to start from. Start
                        building yours with our proven assessments, strategies
                        and tools.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-0 mt-5">
                    <div className="step-boxes p-4 h-100">
                      <div className="step-boxes-numbers">
                        <img src={StepRound} alt="Step 2" />
                        <p className="mb-0">
                          2<span>.</span>
                        </p>
                      </div>
                      <span className="d-block mt-4">Stage two</span>
                      <h3 className="my-3">Live Your Ideal Day</h3>
                      <p>
                        We feel most alive when our "inner world" and our "outer
                        world" are aligned. Take calculated risks to experience
                        your ideal day, over and over again.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mx-lg-0 mx-md-auto mt-lg-0 mt-md-5 mt-5">
                    <div className="step-boxes p-4 h-100">
                      <div className="step-boxes-numbers">
                        <img src={StepRound} alt="Step 2" />
                        <p className="mb-0">
                          3<span>.</span>
                        </p>
                      </div>
                      <span className="d-block mt-4">Stage three</span>
                      <h3 className="my-3">Freedom to Choose</h3>
                      <p>
                        Your life is a journey, not a destination. Apply your
                        new ways of thinking to transform your career, your
                        family and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="pt-5">
              <div className="container h-100">
                <div className="row">
                  <div className="col-lg col-md-4 col-12">
                    <div className="coaches-wrapper p-3 h-100 text-center">
                      <LazyLoadImage
                        alt="Male Coach"
                        src={HeadshotThumbnail1}
                      />
                      <p className="mt-lg-3 mt-md-3 mt-3 mb-0">
                        "This<b className="px-1">unlocked</b>
                        <br />
                        potential that was trapped within me for years..."
                      </p>
                    </div>
                  </div>
                  <div className="col-lg col-md-4 col-12 mt-lg-0 mt-md-0 mt-5">
                    <div className="coaches-wrapper p-3 h-100 text-center">
                      <img src={HeadshotThumbnail2} alt="Male Coach" />
                      <p className="mt-lg-3 mt-md-3 mt-3 mb-0">
                        "This has been a
                        <b className="pl-1"> professional game changer.</b>"
                      </p>
                    </div>
                  </div>
                  <div className="col-lg col-md-4 col-12 mt-lg-0 mt-md-0 mt-5">
                    <div className="coaches-wrapper p-3 h-100 text-center">
                      <img src={HeadshotThumbnail3} alt="Male Coach" />
                      <p className="mt-lg-3 mt-md-3 mt-3 mb-0">
                        "You translated my jumbled thoughts into something
                        <b className="pl-1"> I can understand and act on...</b>"
                      </p>
                    </div>
                  </div>
                  <div className="col-lg col-md-4 col-12 ml-lg-0 ml-md-auto mt-lg-0 mt-md-5 mt-5">
                    <div className="coaches-wrapper p-3 h-100 text-center">
                      <img src={HeadshotThumbnail4} alt="Male Coach" />
                      <p className="mt-lg-3 mt-md-3 mt-3 mb-0">
                        "I have found
                        <b className="px-1"> peace in this life</b>because of
                        the skills you taught me."
                      </p>
                    </div>
                  </div>
                  <div className="col-lg col-md-4 col-12 mr-lg-0 mr-md-auto mt-lg-0 mt-md-5 mt-5 mx-lg-0 mx-md-0 mx-auto">
                    <div className="coaches-wrapper p-3 h-100 text-center">
                      <img src={HeadshotThumbnail5} alt="Male Coach" />
                      <p className="mt-lg-3 mt-md-3 mt-3 mb-0">
                        "I wanted to leave medicine but now
                        <b className="pl-1">I look forward to work.</b>"
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-lg-5 mt-md-5 mt-5 text-lg-center text-md-center text-center">
                    <Link to="/request" className="btn-default btn-purple">
                      Request a Free Coaching Session
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="portfolio-wrapper">
            <article style={{ height: 'inherit' }}>
              <div className="container" style={{ height: 'inherit' }}>
                <div className="row h-100 align-items-center flex-lg-row flex-md-row flex-column-reverse">
                  <div className="col-lg-6 col-md-6 mt-lg-0 mt-md-0 mt-5">
                    <h2 className="text-lg-left text-md-left text-center">
                      Hi, I'm Sandy...
                    </h2>
                    <p className="my-4 text-white">
                      A certified coach for 26 years, I'm on a mission to see
                      every physician lead and live with purpose...
                    </p>
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-lg-2 col-md-4 col-6 mb-4">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialbcc}
                            alt="Board Certified Coach"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 mb-4">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialpccicf}
                            alt="Professional Certified Coach"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 mb-4">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialpci}
                            alt="Physician Coaching Institute"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 mb-4">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialfache}
                            alt="Fache"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-2 col-md-4 col-6 mb-4">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialkolbe}
                            alt="Kolbe"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-lg-left text-md-left text-center">
                      <Link
                        to="/about-us/"
                        className="btn-default btn-purple text-capitalize mt-lg-5 mt-md-5 mt-5"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 ml-lg-auto ml-md-0 mt-lg-0 mt-md-0 mt-5">
                    <LazyLoadImage
                      src={SandyScottImg}
                      alt="Sandy Scott"
                      className="img-fluid w-100"
                    />
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="review-wrapper">
            <article>
              <div className="container main-container">
                <div className="partner-box">
                  <div className="row">
                    <div className="col-lg-12 mx-lg-auto mx-md-auto mx-auto text-lg-center text-md-center text-center">
                      <h2>
                        We partner with systems that value
                        <br />
                        their physicians.
                      </h2>
                    </div>
                    <div className="col-lg-10 mx-lg-auto mx-md-auto mx-auto text-lg-center text-md-center text-center">
                      <p className="py-4">
                        We're here to help organizations develop their physician
                        leadership pipeline, because it's our best shot at
                        making healthcare work better for patients. And we bring
                        together the best strategies, tools and experience to
                        help you do just that.
                      </p>
                    </div>

                    <LogoRow />
                    <div className="col-lg-10 mx-auto px-lg-auto px-md-auto px-0">
                      <TestimonialSwiper />
                    </div>
                    <div className="col-lg-12 text-center">
                      <Link
                        to="/groups"
                        className="btn-default btn-purple text-capitalize"
                      >
                        More About Group Programs
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="join-community">
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-lg-auto text-lg-center text-md-center text-center">
                    <h2 className="mb-5">
                      Join a community of purposeful
                      <br />
                      physician leaders.
                    </h2>
                  </div>
                </div>
                <div className="row h-auto">
                  <div className="col-lg-4 col-md-6 ml-lg-auto ml-md-auto h-auto">
                    <div
                      className="podcast-wrapper h-auto"
                      style={{ background: '#fff' }}
                    >
                      <LazyLoadImage
                        alt="High Impact Physician Podcast"
                        className="img-fluid"
                        height="100%"
                        width="100%"
                        src={PhysicianPodcast}
                      />
                      <div className="p-4">
                        <p className="text-center">
                          We believe great physicians make great leaders, so
                          we're uncovering insights through candid conversations
                          with world-class clinical thought leaders
                        </p>
                        <div className="text-center btn-wrapper">
                          <a
                            href="https://podcasts.apple.com/us/podcast/high-impact-physician/id1493857679"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-default btn-purple text-capitalize w-75"
                          >
                            <LazyLoadImage
                              height="10%"
                              width="10%"
                              src={MusicIcon}
                              alt="Music Icon"
                              className="mr-2"
                            />
                            Listen on Apple
                          </a>
                        </div>
                        <div className="text-center btn-wrapper">
                          <a
                            href="https://open.spotify.com/show/4zHLgxkRP4LtH0E5riHRkE"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn-default btn-purple text-capitalize mt-3 w-75"
                          >
                            <LazyLoadImage
                              height="10%"
                              width="10%"
                              src={SpotifyIcon}
                              alt="Music Icon"
                              className="mr-2"
                            />
                            Listen on Spotify
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mr-lg-auto mr-md-auto h-100 mt-lg-0 mt-md-0 mt-5">
                    <div
                      className="resources-wrapper"
                      style={{ background: '#fff' }}
                    >
                      <LazyLoadImage
                        alt="High Impact Physician Resources"
                        className="img-fluid"
                        height="100%"
                        width="100%"
                        src={PhysicianResources}
                      />
                      <div className="p-4">
                        <p className="text-center">
                          Each month we send our best resources to our favorite
                          peoples' inboxes. It's one way we're trying to pay it
                          forward and make a difference in the world.
                        </p>
                        <p className="text-center">
                          You can expect a distilled set of case studies, mental
                          models, tips for meetings and anything else we think
                          might support you to lead and live with purpose
                        </p>
                        <div
                        // id="resource-form-wrapper"
                        // style={{
                        //   height: '90vh',
                        //   marginTop: '10rem',
                        // }}
                        >
                          <iframe
                            src="../../subscription-form.html"
                            title="Resources Form"
                            width="100%"
                            border="none"
                            className="w-100"
                            style={{ border: 'none', height: '18rem' }}
                          />
                        </div>
                        {/* <SubscriptionForm /> */}
                        {/* <ResourcesForm
                          wrapper="subscription-form"
                          lead="Each month we send our best resources to our favorite peoples'
                        inboxes. It's one way we're trying to pay it forward and make a
                        difference in the world."
                          leadsec="You can expect a distilled set of case studies, mental models,
                        tips for meetings and anything else we think might support you
                        to lead and live with purpose."
                          textAl="text-center"
                          submitBtn="btn-default btn-purple w-100"
                          btntitle="Send Me Practical Resources"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section
            className="send-a-message-wrapper"
            style={{ background: '#E4D5C9', padding: '100px 0' }}
          >
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto text-center">
                    <h2>
                      Want to achieve that secret dream?
                      <br />
                      Send us a message.
                    </h2>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-lg-6 ml-lg-auto">
                    <LazyLoadImage
                      alt="Us"
                      className="img-fluid"
                      height="100%"
                      width="100%"
                      src={UsImg}
                    />
                  </div>

                  <div className="col-lg-6 col-md-10 mr-lg-auto mx-lg-0 mx-md-auto mt-lg-0 mt-md-5">
                    <SendAMessage />
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </Layout>
    </>
  )
}
export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allArticlesJson {
      edges {
        node {
          title
          image
          url
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            banner
            date(formatString: "MMM DD YYYY")
            title
          }
        }
      }
    }
  }
`
